import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "loading"
}
const _hoisted_2 = {
  key: 1,
  class: "loaded"
}
const _hoisted_3 = {
  key: 0,
  class: "center"
}
const _hoisted_4 = {
  key: 1,
  class: "cart"
}
const _hoisted_5 = {
  key: 1,
  class: "cart-item-cell"
}
const _hoisted_6 = {
  key: 2,
  class: "cart-item header"
}
const _hoisted_7 = {
  key: 3,
  class: "form-aligned-fullspan small"
}
const _hoisted_8 = { class: "cart-item footer" }
const _hoisted_9 = { class: "cart-item-cell pricecol" }
const _hoisted_10 = { class: "cart-item" }
const _hoisted_11 = { class: "cart-item-cell namecol" }
const _hoisted_12 = { class: "cart-item-cell pricecol" }
const _hoisted_13 = { class: "form-aligned-fullspan small" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("main", null, [
    ($setup.loaded === false)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("h1", null, "Loading", -1 /* HOISTED */)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          ($setup.orderStore.cart.length === 0 && $setup.orderStore.recipes.length === 0)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_3, "Add Items To Your Cart"))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                ($props.editmode || $setup.orderStore.recipes.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "cart-item header" }, [
                        _createElementVNode("div", { class: "cart-item-cell" }, "Recipes")
                      ], -1 /* HOISTED */)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.orderStore.recipes, (recipe) => {
                        return (_openBlock(), _createBlock($setup["CartRecipe"], {
                          key: 'recipeID-' + recipe.id,
                          recipe: recipe,
                          editmode: $props.editmode,
                          onEditRecipe: $setup.handleEditRecipe
                        }, null, 8 /* PROPS */, ["recipe", "editmode"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true),
                ($setup.orderStore.recipes.length === 0 && $props.editmode)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                      _createElementVNode("h4", { class: "darkred" }, "Click \"Build Your Recipe\" To Order Ingredients For Your Next Brewday", -1 /* HOISTED */)
                    ])))
                  : _createCommentVNode("v-if", true),
                ($setup.orderStore.cart.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[3] || (_cache[3] = [
                      _createElementVNode("div", { class: "cart-item-cell" }, "Items", -1 /* HOISTED */)
                    ])))
                  : _createCommentVNode("v-if", true),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.orderStore.cart, (item) => {
                  return (_openBlock(), _createBlock($setup["CartItem"], {
                    item: item,
                    canonicalCategory: $setup.getShopCategoryByID(item.canonical_category),
                    key: 'itemSKU-' + item.sku,
                    editmode: $props.editmode,
                    onCloseHeaderCart: $setup.closeHeaderCart
                  }, null, 8 /* PROPS */, ["item", "canonicalCategory", "editmode"]))
                }), 128 /* KEYED_FRAGMENT */)),
                ($setup.orderStore.cartNeedsMilling)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_7, _cache[4] || (_cache[4] = [
                      _createTextVNode("NOTE: Grains Ordered Here Will Be Packaged Separately."),
                      _createElementVNode("br", null, null, -1 /* HOISTED */),
                      _createTextVNode("Click \"Build Your Recipe\" To Order Combined Grains.")
                    ])))
                  : _createCommentVNode("v-if", true),
                _createElementVNode("div", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "cart-item-cell namecol" }, null, -1 /* HOISTED */)),
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "cart-item-cell pricecol" }, null, -1 /* HOISTED */)),
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "cart-item-cell inputcol" }, "Subtotal:", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_9, "$" + _toDisplayString($setup.orderStore.grossSubtotal.toFixed(2)), 1 /* TEXT */)
                ]),
                ($setup.orderStore.millCount > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, "Bags Milled: " + _toDisplayString($setup.orderStore.millCount), 1 /* TEXT */),
                        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "cart-item-cell pricecol" }, null, -1 /* HOISTED */)),
                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "cart-item-cell inputcol" }, "Mill Fee", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_12, "$" + _toDisplayString($setup.orderStore.totalMillingFee.toFixed(2)), 1 /* TEXT */)
                      ]),
                      _createElementVNode("p", _hoisted_13, [
                        _cache[10] || (_cache[10] = _createTextVNode("NOTE: Mill Fees only applied to shipping orders")),
                        _createVNode(_component_router_link, {
                          to: "/shipping#millfee",
                          onClick: $setup.closeHeaderCart
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("img", {
                              src: $setup.cdnpath + '/images/icons/outline_info_black_18dp.png'
                            }, null, 8 /* PROPS */, _hoisted_14)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ], 64 /* STABLE_FRAGMENT */))
                  : _createCommentVNode("v-if", true)
              ]))
        ]))
  ]))
}