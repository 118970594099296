import { defineComponent as _defineComponent } from 'vue'
import { inject, ref } from "vue"
import { CDNKey } from "./../../pos/keys"

export default /*@__PURE__*/_defineComponent({
  __name: 'gbModalProcessing',
  setup(__props, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)
const headerMessage = ref("Processing...")
const additionalMessage = ref(false)

const beforeOpen = (params): void => {
	if (params) {
		if (params.headerMessage) headerMessage.value = params.headerMessage
		if (params.additionalMessage)
			additionalMessage.value = params.additionalMessage
	}
}

const __returned__ = { cdnpath, headerMessage, additionalMessage, beforeOpen, inject, ref, get CDNKey() { return CDNKey } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})