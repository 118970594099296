import { defineComponent as _defineComponent } from 'vue'
import { computed, inject } from "vue"

//types
import { CDNKey } from "@website/keys"
import type { IShopCartItem } from "types/ShopCartItem"


export default /*@__PURE__*/_defineComponent({
  __name: 'cart-recipe-item',
  props: {
    item: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const props = __props

const productThumb = computed((): string => {
	if (props.item?.photos?.v50px) {
		return cdnpath + "/images/products/" + props.item.photos.v50px
	} else {
		return cdnpath + "/images/products/gnome-thumb.jpg"
	}
})

const __returned__ = { cdnpath, props, productThumb, computed, inject, get CDNKey() { return CDNKey } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})