import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "billboard" }
const _hoisted_2 = { class: "billboard-imagebox" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("article", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "billboard-textbox" }, [
      _createElementVNode("h1", { class: "center" }, "Sorry, we couldn't find the page you're looking for.")
    ], -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.cdnpath + '/images/logos/gnome-brew-logo-with-text-full-color.png'
      }, null, 8 /* PROPS */, _hoisted_3)
    ])
  ]))
}