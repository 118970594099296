import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { useGetInventory } from "@website/api/inventory"
import { useGetShopCategories } from "@website/api/shopCategories"
import { CDNKey } from "@website/keys"
import type { FuseIndex, IFuseOptions } from "fuse.js"
import Fuse from "fuse.js"
import debounce from "lodash-es/debounce"
import type { IPublicInventoryItem } from "types/InventoryItem"
import type { IShopCategory } from "types/ShopCategory"
import { computed, inject, onBeforeMount, ref, toValue } from "vue"
import { useRouter } from "vue-router"
import SearchItem from "./search-item.vue"

type TSearchItemType = "item" | "category"
export interface ISearchItem {
	score: number
	searchTerm: string
	type: TSearchItemType
	item: IPublicInventoryItem | IShopCategory
}

const resultsLimit = 20

export default /*@__PURE__*/_defineComponent({
  __name: 'search',
  emits: ["closeHeaderMenu"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const emit = __emit
const router = useRouter()
const checkScreenSize = (): void => {
	if (window.innerWidth < 700) isSmallScreen.value = true
	else isSmallScreen.value = false
}
onBeforeMount(() => {
	window.addEventListener("resize", checkScreenSize)
	checkScreenSize()
})

const isSearchLoading = computed<boolean>(() => {
	return (
		inventoryQueryStatus.value !== "success" ||
		!categoryQueryIsSuccess.value
	)
})

const { inventory, status: inventoryQueryStatus } = useGetInventory()
const { shopCategories: categories, isSuccess: categoryQueryIsSuccess } =
	useGetShopCategories()

const showSearchBox = computed((): boolean => {
	if (isSmallScreen.value === false) return true
	else return showSearch.value
})

const searchTerm = ref("")

const itemFuse = ref<Fuse<IPublicInventoryItem>>()
const categoryFuse = ref<Fuse<IShopCategory>>()
const itemOptions: IFuseOptions<IPublicInventoryItem> = {
	keys: ["name", "search_keywords", "description_short", "description_long"],
	threshold: 0.3,
	ignoreLocation: true,
	includeScore: true,
}
const categoryOptions: IFuseOptions<IShopCategory> = {
	keys: ["name"],
	threshold: 0.3,
	ignoreLocation: true,
	includeScore: true,
}
const itemsIndex = ref<FuseIndex<Required<IPublicInventoryItem>> | undefined>(
	undefined,
)
const categoriesIndex = ref<FuseIndex<Required<IShopCategory>> | undefined>(
	undefined,
)
const clickListenerActive = ref(false)
const touchListenerActive = ref(false)
const isSmallScreen = ref(false)
const showSearch = ref(false)
const results = ref<ISearchItem[]>([])

//move to server side
const updateIndex = (): Promise<void> => {
	$log.info("[appHeader_Search updatingIndex()]")
	try {
		return new Promise((resolve) => {
			if (
				inventory.value === undefined ||
				categories.value === undefined ||
				itemOptions.keys === undefined ||
				categoryOptions.keys === undefined
			) {
				return
			}
			itemsIndex.value = Fuse.createIndex(
				itemOptions.keys,
				inventory.value,
			)
			itemFuse.value = new Fuse(
				inventory.value,
				itemOptions,
				itemsIndex.value,
			)
			categoriesIndex.value = Fuse.createIndex(
				categoryOptions.keys,
				categories.value,
			)
			categoryFuse.value = new Fuse(
				categories.value,
				categoryOptions,
				categoriesIndex.value,
			)
			resolve(void 0)
		})
	} catch (error: unknown) {
		$log.error(error, "[appHeader_Search updateIndex()]")
		return Promise.reject(error)
	}
}
const search = async () => {
	try {
		if (itemFuse.value === undefined || categoryFuse.value === undefined)
			await updateIndex()
		if (itemFuse.value === undefined || categoryFuse.value === undefined)
			return
		if (!clickListenerActive.value)
			window.addEventListener("click", checkForClickToClose)
		if (!touchListenerActive.value)
			window.addEventListener("touchend", checkForClickToClose)
		const itemResults = itemFuse.value.search(searchTerm.value)
		const categoryResults = categoryFuse.value.search(searchTerm.value)
		if (itemResults && categoryResults) {
			const mappedItems = itemResults.map((item) => {
				return {
					score: item.score,
					type: "item",
					searchTerm: searchTerm.value,
					item: item.item,
				}
			}) as ISearchItem[]
			const mappedCategories = categoryResults.map((category) => {
				return {
					score: category.score,
					type: "category",
					searchTerm: searchTerm.value,
					item: category.item,
				}
			}) as ISearchItem[]
			results.value = [...mappedCategories, ...mappedItems]
			//sort lowest to highest
			results.value.sort((a, b) => a.score - b.score)
			//limit results
			results.value = results.value.slice(0, resultsLimit)
		}
		const nResults = toValue(results.value.length)
		$log.info("Shop Header Search", {
			searchTerm: searchTerm.value,
			resultsCount: nResults,
		})
	} catch (error: unknown) {
		$log.error(
			error,
			"[appHeader_Search search()]",
			{ itemFuse: itemFuse.value },
			{ categoryFuse: categoryFuse.value },
			{ nResults: results.value.length },
		)
	}
}

const resetSearch = () => {
	searchTerm.value = ""
	results.value = []
	showSearch.value = false
	window.removeEventListener("touchend", checkForClickToClose)
	window.removeEventListener("click", checkForClickToClose)
}

const toggleSearchBar = (): void => {
	if (isSmallScreen.value === false) return
	if (showSearch.value) closeSearchBar()
	else openSearchBar()
}
const openSearchBar = (): void => {
	showSearch.value = true
	emit("closeHeaderMenu")
	if (!touchListenerActive.value)
		window.addEventListener("touchend", checkForClickToClose)
	window.setTimeout(function () {
		document.getElementById("searchbox")?.focus()
	}, 0)
}
const closeSearchBar = (): void => {
	showSearch.value = false
}
const checkForClickToClose = (event: Event): void => {
	const searchArea = document.getElementById("search")
	if (
		searchArea &&
		event.target instanceof Node &&
		!searchArea.contains(event.target)
	)
		resetSearch()
}

const goToItem = (item, href: string, resultRank: number) => {
	$log.info("Shop Search Result Clicked", {
		sku: item.sku,
		name: item.name,
		searchTerm: searchTerm.value,
		resultRank,
	})
	resetSearch()
	router.push(href)
}

const debouncedSearch = debounce(search, 250)
const filteredResults = computed(() => {
	return results.value.slice(0, resultsLimit)
})

const getKey = (searchItem: ISearchItem): string => {
	let key = searchItem.type
	if (searchItem.type === "item" && "sku" in searchItem.item)
		key += searchItem.item.sku
	else if ("id" in searchItem.item) key += searchItem.item.id
	return key
}

const __returned__ = { cdnpath, emit, router, checkScreenSize, isSearchLoading, inventory, inventoryQueryStatus, categories, categoryQueryIsSuccess, showSearchBox, searchTerm, itemFuse, categoryFuse, itemOptions, categoryOptions, itemsIndex, categoriesIndex, resultsLimit, clickListenerActive, touchListenerActive, isSmallScreen, showSearch, results, updateIndex, search, resetSearch, toggleSearchBar, openSearchBar, closeSearchBar, checkForClickToClose, goToItem, debouncedSearch, filteredResults, getKey, get $log() { return $log }, get useGetInventory() { return useGetInventory }, get useGetShopCategories() { return useGetShopCategories }, get CDNKey() { return CDNKey }, get Fuse() { return Fuse }, get debounce() { return debounce }, computed, inject, onBeforeMount, ref, toValue, get useRouter() { return useRouter }, SearchItem }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})