import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import Cart from "@website/components/cart/cart.vue"
import { useOrderStore } from "@website/stores/order.js"
import { onMounted, ref } from "vue"
import { event as GtagEvent } from "vue-gtag"
import { useRouter } from "vue-router"

import { storeToRefs } from "pinia"


export default /*@__PURE__*/_defineComponent({
  __name: 'header-cart',
  emits: ["closeHeaderMenu"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit

const router = useRouter()
const orderStore = useOrderStore()
const { itemCount } = storeToRefs(orderStore)

const isCartOpen = ref(false)
const clickListenerActive = ref(false)

const toggleOrderBagOpen = (): void => {
	if (isCartOpen.value === true) closeHeaderCart()
	else if (isCartOpen.value === false) openHeaderCart()
}
const openHeaderCart = (): void => {
	isCartOpen.value = true
	emit("closeHeaderMenu")
	if (!clickListenerActive.value) {
		window.addEventListener("click", checkForClickToClose)
		clickListenerActive.value = true
	}
}
const closeHeaderCart = (): void => {
	isCartOpen.value = false
	window.removeEventListener("click", checkForClickToClose)
	clickListenerActive.value = false
}

const checkForClickToClose = (event: MouseEvent): void => {
	if (clickListenerActive.value === false) return
	const detectionArea = document.getElementById("header-cart")
	if (
		detectionArea &&
		event.target instanceof Node &&
		detectionArea.contains(event.target) === false
	) {
		closeHeaderCart()
	}
}
const checkForSwipeToClose = (
	event: TouchEvent,
	touchStartY: number,
	screenStartY: number,
): void => {
	const touchEndY = event.changedTouches[0].clientY
	const screenEndY = window.screenY
	if (screenStartY === screenEndY && touchEndY > touchStartY) {
		const screenHeight = window.innerHeight
		const touchMovement = touchEndY - touchStartY
		const percentOfScreen = touchMovement / screenHeight
		//5.7.2024 - changed from 0.25 to 0.22
		//9.24.24 - changed from 0.22 to 0.2
		if (percentOfScreen > 0.2) {
			$log.info("[Header_Cart] Cart Pulled Down > 0.2 - Closed", {
				screenHeight,
				touchMovement,
				percentOfScreen,
			})
			closeHeaderCart()
		} else {
			$log.info("[Header_Cart] Cart Pulled Down < 0.2 - Not Closed", {
				screenHeight,
				touchMovement,
				percentOfScreen,
			})
		}
	}
}
onMounted(() => {
	//Detect when user swipes down on cart
	const headerCartWrapper = document.getElementById("header-cart-wrapper")
	headerCartWrapper?.addEventListener(
		"touchstart",
		(event) => {
			const touchStartY = event.touches[0].clientY
			const screenStartY = window.screenY
			const touchEndHandler = (event: TouchEvent) => {
				checkForSwipeToClose(event, touchStartY, screenStartY)
				headerCartWrapper.removeEventListener(
					"touchend",
					touchEndHandler,
				)
			}
			headerCartWrapper.addEventListener("touchend", touchEndHandler)
		},
		{ passive: true },
	)
})

const checkout = (): void => {
	closeHeaderCart()
	router.push("/checkout").catch((error) => {
		if (error.name !== "NavigationDuplicated") throw error
	})
	$log.info("Checkout - HeaderCart -> Confirm")

	///!!!!!  @TODO items needs to be formated item, "items" is just a placeholder
	try {
		GtagEvent("begin_checkout")
	} catch (error) {
		$log.error(
			error as object,
			"[Header_Cart checkout() GtagEvent('begin_checkout')]",
		)
	}
}

const clearCart = (): void => {
	if (orderStore.cart.length === 0 && orderStore.recipes.length === 0) return
	const confirmation = window.confirm(
		"Are You Sure You'd Like To Empty Your Bag?",
	)
	if (confirmation === false) return
	orderStore.cart.forEach((item) => {
		//item.isSaved = false
		item.quantity = 0
	})
	orderStore.clearCart()
	$log.info("[Header_Cart] Clear Cart")
	closeHeaderCart()
}

const __returned__ = { emit, router, orderStore, itemCount, isCartOpen, clickListenerActive, toggleOrderBagOpen, openHeaderCart, closeHeaderCart, checkForClickToClose, checkForSwipeToClose, checkout, clearCart, get $log() { return $log }, Cart, get useOrderStore() { return useOrderStore }, onMounted, ref, get GtagEvent() { return GtagEvent }, get useRouter() { return useRouter }, get storeToRefs() { return storeToRefs } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})