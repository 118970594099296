import { defineComponent as _defineComponent } from 'vue'
import { logger as $log } from "@Plugins/clientLogger"
import { CDNKey } from "@website/keys"
import { useAccountStore } from "@website/stores/account"
import { storeToRefs } from "pinia"
import { inject, onMounted, ref } from "vue"
import AppHeader_Account from "./appHeader-account.vue"
import HeaderCart from "./header-cart.vue"
import Search from "./search/search.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'appHeader',
  setup(__props, { expose: __expose }) {
  __expose();

const cdnpath = inject<string>(CDNKey)

const accountStore = useAccountStore()
const { isLoggedIn } = storeToRefs(accountStore)
const isHamburgerMenuOpen = ref(false)
const toggleMenu = (): void => {
	if (isHamburgerMenuOpen.value === true) {
		closeMenu()
	} else {
		openMenu()
	}
}
const openMenu = (): void => {
	isHamburgerMenuOpen.value = true
	document.body.style.overflow = "hidden"
}
const closeMenu = (): void => {
	isHamburgerMenuOpen.value = false
	document.body.style.overflow = "auto"
}

const checkForSwipeToClose = (
	event: TouchEvent,
	touchStartY: number,
	screenStartY: number,
): void => {
	const touchEndY = event.changedTouches[0].clientY
	const screenEndY = window.screenY
	if (screenStartY === screenEndY && touchEndY > touchStartY) {
		const screenHeight = window.innerHeight
		const touchMovement = touchEndY - touchStartY
		const percentOfScreen = touchMovement / screenHeight
		//10.23.2024 - changed from 0.25 to 0.2
		if (percentOfScreen > 0.2) {
			$log.info("[AppHeader] Menu Pulled Down > .20 - Closed", {
				screenHeight,
				touchMovement,
				percentOfScreen,
			})
			closeMenu()
		} else {
			$log.info("[AppHeader] Menu Pulled Down < .20 - Not Closed", {
				screenHeight,
				touchMovement,
				percentOfScreen,
			})
		}
	}
}

onMounted(() => {
	const topMenu = document.getElementById("topmenu")
	topMenu?.addEventListener(
		"touchstart",
		(event) => {
			const touchStartY = event.touches[0].clientY
			const screenStartY = window.screenY
			const touchEndHandler = (event: TouchEvent) => {
				checkForSwipeToClose(event, touchStartY, screenStartY)
				topMenu.removeEventListener("touchend", touchEndHandler)
			}
			topMenu.addEventListener("touchend", touchEndHandler)
		},
		{ passive: true },
	)
})

const __returned__ = { cdnpath, accountStore, isLoggedIn, isHamburgerMenuOpen, toggleMenu, openMenu, closeMenu, checkForSwipeToClose, get $log() { return $log }, get CDNKey() { return CDNKey }, get useAccountStore() { return useAccountStore }, get storeToRefs() { return storeToRefs }, inject, onMounted, ref, AppHeader_Account, HeaderCart, Search }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})