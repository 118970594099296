import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_gbModal = _resolveComponent("gbModal")!

  return (_openBlock(), _createBlock(_component_gbModal, {
    name: "gbModal-processing",
    height: "auto",
    adaptive: true,
    clickToClose: false,
    onBeforeOpen: $setup.beforeOpen
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h2", _hoisted_1, _toDisplayString($setup.headerMessage), 1 /* TEXT */),
        ($setup.additionalMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.additionalMessage), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("img", {
          class: "center",
          src: $setup.cdnpath + '/images/icons/load-spinner.gif'
        }, null, 8 /* PROPS */, _hoisted_3)
      ])
    ]),
    _: 1 /* STABLE */
  }))
}