import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "noprint",
  id: "appFooter"
}
const _hoisted_2 = { class: "pagefooter-element" }
const _hoisted_3 = {
  class: "social-button",
  target: "blank",
  href: "https://www.facebook.com/gnomebrew/"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "social-button",
  target: "blank",
  href: "https://www.instagram.com/gnomebrew/"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"pagefooter-element\"><h2>Regular Hours</h2><table><tbody><tr><td>Mon</td><td>Closed</td></tr><tr><td>Tue - Fri</td><td>11 - 7 PM</td></tr><tr><td>Sat</td><td>11 - 6 PM</td></tr><tr><td>Sun</td><td>11 - 5 PM</td></tr></tbody></table></div><div class=\"pagefooter-element\"><h2>Contact Us</h2><table><tbody><tr><td><a href=\"tel:773.961.7750\">773.961.7750</a></td></tr><tr><td><a href=\"mailto:gnomes@gnomebrewshop.com\">gnomes@gnomebrewshop.com</a></td></tr><tr><td>2026 W. Montrose Ave.<br>Chicago, IL 60618</td></tr></tbody></table></div>", 2)),
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("a", { href: "/local-delivery" }, [
        _createElementVNode("h3", null, "Same Day Local Delivery ")
      ], -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("a", { href: "/shipping" }, [
        _createElementVNode("h3", null, "Shipping Policy")
      ], -1 /* HOISTED */)),
      _cache[2] || (_cache[2] = _createElementVNode("h2", null, "Follow Us", -1 /* HOISTED */)),
      _createElementVNode("a", _hoisted_3, [
        _createElementVNode("img", {
          src: $setup.cdnpath + '/images/logos/facebook-logo-72x72.png',
          alt: "facebook"
        }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      _createElementVNode("a", _hoisted_5, [
        _createElementVNode("img", {
          src: $setup.cdnpath + '/images/logos/instagram-logo-72x72.png',
          alt: "instagram"
        }, null, 8 /* PROPS */, _hoisted_6)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("p", null, "© 2018 - " + _toDisplayString(new Date().getFullYear()) + " Gnome Brew, LLC. All Rights Reserved.", 1 /* TEXT */)
    ])
  ]))
}