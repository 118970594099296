import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "cart-item" }
const _hoisted_2 = { class: "cart-item-cell imagecol" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "cart-item-cell namecol" }
const _hoisted_5 = { class: "cart-item-cell pricecol" }
const _hoisted_6 = { class: "cart-item-cell inputcol" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "cart-item-cell pricecol" }
const _hoisted_9 = {
  key: 0,
  class: "cart-item-cell buttoncol"
}
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: $setup.image }, null, 8 /* PROPS */, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString($props.item.name), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_5, "$" + _toDisplayString($props.item.price.toFixed(2)), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_6, [
      ($props.editmode)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            type: "text",
            pattern: "[0-9]*",
            inputmode: "decimal",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.inputQuantity) = $event)),
            onKeydown: [
              _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => ($setup.convertOunces($event)), ["prevent"]), ["o"])),
              _cache[2] || (_cache[2] = ($event: any) => ($setup.preventInvalidDecimalKey($event)))
            ],
            onInput: $setup.updateItemQuantity
          }, null, 544 /* NEED_HYDRATION, NEED_PATCH */)), [
            [
              _vModelText,
              $setup.inputQuantity,
              void 0,
              { number: true }
            ]
          ])
        : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString($props.item.quantity), 1 /* TEXT */))
    ]),
    _createElementVNode("div", _hoisted_8, "$" + _toDisplayString(($props.item.price * $props.item.quantity).toFixed(2)), 1 /* TEXT */),
    ($props.editmode)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("button", {
            class: "button-icon",
            onClick: _withModifiers($setup.removeItemInRecipe, ["stop"]),
            tabindex: "-1",
            title: "Remove Item In Recipe"
          }, [
            _createElementVNode("img", {
              src: $setup.cdnpath + '/images/icons/baseline_clear_black_18dp.png'
            }, null, 8 /* PROPS */, _hoisted_10)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}